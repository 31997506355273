import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: true,
        buscador: true
    }

    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    registro = {
        enableCarreras: true,
        carrerasObligatorio: true,
        universidades: ["Universidad Bernardo O'Higgins"],
        amount: 3,
        enableTelefono: true,
        telefonoObligatorio: true,
        enableNivel: true,
        nivelObligatorio: true,
        enableColegio: true,
        colegioObligatorio: true,
        enableFechaNacimiento: true,
        fechaNacimientoObligatorio: true,
        nombresObligatorio: true,
        apellidoPaternoObligatorio: true,
        apellidoMaternoObligatorio: true
    }
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquareLight.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            navbar: {
                logo: "logoSmallLight",
                logoMobile: "logoSquareLight"
            },
            footer: {
                logo: "logoSmallLight",
                logosExtras: [
                    {
                        src: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ubo/logos/acceso-fondo-oscuro.png",
                        link: "#",
                        title: "Acceso Educación Superior"
                    },
                    {
                        src: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ubo/logos/admision-2023.png",
                        link: "#",
                        title: "Admisión 2023"
                    },
                    {
                        src: "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/ubo/logos/acreditada.png",
                        link: "#",
                        title: "Acreditada"
                    }
                ]
            },
            landing: {
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/160b357996dadbcd12c765d396dc14b1imagen de fondo PREUBO (1).png",
                logo: "logoLarge",
                subtitle: null,
                subtitleHasShadow: false,
                logoFooter: "logoSmallLight"
            },
            sharedLayout: {
                backgroundImage:
                    "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/clones/786041bd8ce54b3d58492cd15fbada49Background 2880x1600.png",
                logo: null
            }
        },
        navigationByApp: {
            alumnos: [
                {
                    icon: "editar-prueba",
                    name: "Ejercita",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "prueba",
                    name: "Practica",
                    route: "/evaluaciones/recomendadas"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/pruebas/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes adaptativos",
                    route: "/planes_personales"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase",
                    route: "/plan_clases"
                }
            ],
            profesores: [
                {
                    icon: "libro2-o",
                    name: "Blog",
                    route: "/blog"
                },
                {
                    icon: "editar-prueba",
                    name: "Evaluar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "upload",
                    name: "Corregir",
                    route: "/evaluacion_instancias/subir"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/evaluaciones/resultados"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase",
                    route: "/plan_clases"
                },
                {
                    icon: "megafono-o",
                    name: "Mis noticias",
                    route: "/noticias"
                },
                {
                    icon: "planificacion-o",
                    name: "Planes de clase predefinido",
                    route: "/plan_clases_predefinidos"
                },
                {
                    icon: "dialogos",
                    name: "Comunidad",
                    route: "/dudas_categorias"
                },
                {
                    icon: "dialogos",
                    name: "Centro de ayuda",
                    route: "/ayudas"
                },
                {
                    icon: "resultados",
                    name: "Resumen de uso",
                    route: "/estadisticas/resumen_uso"
                }
            ]
        },
        showcasesByApp: {
            alumnos: [
                {
                    orden: 0,
                    nombre: "Datos Usuario",
                    columna: 0,
                    selected: true,
                    identificador: "usuario-showcasebox"
                },
                {
                    orden: 0,
                    nombre: "Noticias",
                    columna: 1,
                    selected: true,
                    identificador: "noticias-alumnos-showcasebox"
                },
                {
                    orden: 0,
                    nombre: "Banners",
                    columna: 2,
                    selected: true,
                    identificador: "arte-home-showcasebox"
                }
            ],
            profesores: []
        },
        menuConfig: {
            tipo: "horizontal"
        },
        informacionContacto: ["Si tienes consultas o comentarios escríbenos a contactoubo@ogr.cl"]
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            id: null,
            asignatura: "Matemática",
            icono: "matematica",
            clase: "matematica",
            abreviacion: "Matemática",
            asignaturaPlataforma: "Matemática",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Lenguaje",
            icono: "lenguaje",
            clase: "lenguaje",
            abreviacion: "Lenguaje",
            asignaturaPlataforma: "Competencias Lectoras",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Ciencias Naturales",
            icono: "quimica",
            clase: "ciencias",
            abreviacion: "Ciencias Naturales",
            asignaturaPlataforma: "Ciencias Naturales",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        },
        {
            asignatura: "Historia y Ciencias Sociales",
            icono: "historia",
            clase: "historia",
            abreviacion: "Historia y Ciencias Sociales",
            asignaturaPlataforma: "Historia y Ciencias Sociales",
            evaluacionTipo: ["paes", "diagnostico paes"],
            materialVisible: true
        },
        {
            asignatura: "Orientación",
            icono: "comunidad",
            clase: "ed-ciudadana",
            abreviacion: "Orientación",
            asignaturaPlataforma: "Orientación",
            evaluacionTipo: ["paes"],
            materialVisible: true
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "UBO",
        id: null,
        pais: "chile",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        info: {
            social: {
                facebook: "https://www.facebook.com/uboadmision",
                twitter: "https://twitter.com/ubohiggins?lang=es",
                youtube: "https://www.youtube.com/watch?v=GTRLUAmYHp8&ab_channel=UBOTV",
                instagram: "https://www.instagram.com/admision.ubo/?hl=es-la"
            },
            soporte: {
                mail: "contactoubo@ogr.cl",
                telefono: null
            },
            contacto: {
                mail: "contactoubo@ogr.cl",
                telefono: null
            },
            companyName: "Universidad Bernardo O'Higgins",
            companySite: "https://www.ubo.cl/"
        },
        evaluacionDefault: "paes",
        evaluacionTipoAdaptiveDefault: "diagnostico paes",
        resumenGeneral: {
            curriculum: "curricular colombia",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["paes", "diagnostico paes"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "curricular colombia",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "retroalimentacion",
                label: "Retroalimentaciones"
            }
        ],
        sesionAlias: null,
        identificadorUsuarioAlias: null,
        evaluacionResponder: "estandar",
        locale: "es-cl",
        disableZopim: true,
        disableNivelCalugas: true,
        canTextHighlight: true
    } as PlataformaType

    evaluaciones = {
        paes: {
            alias: "PAES",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["Eje PAES"],
                estadisticasEvolutivas: ["Eje PAES"],
                desempenoEvaluacion: "Eje PAES",
                groupBy: ["Eje PAES,Unidad Temática PAES"],
                clasificacionTiposTablaEspecificaciones: ["Eje PAES", "Unidad Temática PAES"],
                methods: "clasificaciones",
                referencesTo: {
                    "Unidad Temática PAES": "Eje PAES"
                },
                configuration: [
                    {
                        name: "Eje PAES",
                        htmlId: "Eje_PAES",
                        hasHijos: true,
                        clasificacionTipo: "Eje PAES"
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "Eje PAES",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Eje PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "Unidad Temática PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "Habilidad PAES",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursosMaterial: [],
            estrellas: [
                {
                    max: 266,
                    min: 150
                },
                {
                    max: 383,
                    min: 267
                },
                {
                    max: 500,
                    min: 384
                },
                {
                    max: 617,
                    min: 501
                },
                {
                    max: 734,
                    min: 618
                },
                {
                    max: 850,
                    min: 735
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases Prueba de transición",
            planPersonalClasificacionMateriales: []
        },
        "diagnostico paes": {
            cursos: [],
            estrellas: [],
            cursosMaterial: [],
            clasificaciones: {
                groupBy: [],
                methods: "clasificaciones",
                estadisticas: [],
                referencesTo: {},
                configuration: [],
                cambiarPregunta: [],
                clasificacionTiposTablaEspecificaciones: ["Eje PAES", "Unidad Temática PAES"]
            },
            rangoCalificacion: {
                max: 1000,
                min: 100,
                tickInterval: 50
            },
            clasificacionMaterial: {},
            clasificacionTiposEstadisticas: [],
            nivelTipoInstrumentoInformeFinal: [],
            planPersonalClasificacionMateriales: []
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o RUT",
        invalidLoginId: "Debes incluir un correo electrónico o RUT válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o RUT/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }
}
